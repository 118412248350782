<template>
  <div>
    <v-btn depressed rounded color="primary" :disabled="disabledButtonUploadCsv" @click="selectDocument">{{
      $t('user.create_by_csv')
    }}</v-btn>
    <input id="filenuevo" ref="filenew" type="file" accept="*.csv" style="display: none" @change="onFileChangeNew" />

    <!-- Modal Confirmar Subir Documento -->
    <v-dialog v-model="dialog_confirm.dialog" dialog_delete max-width="600" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialog_confirm.titulo }}</span>
        </v-card-title>
        <v-card-text>
          <p>{{ dialog_confirm.texto }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="disabledButton" color="grey darken-1" text @click.native="dialog_confirm.dialog = false"
            ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
          >
          <v-btn
            :disabled="disabledButton"
            text
            :color="dialog_confirm.btn_confirm_color + ' darken-1'"
            @click.native="
              dialog_confirm.dialog = false;
              uploadCsvFile();
            "
            ><v-icon>{{ dialog_confirm.btn_confirm_icono }}</v-icon> {{ dialog_confirm.btn_confirm_texto }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Error Documento -->
    <v-dialog v-model="dialog_error.dialog" max-width="500px" scrollable persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialog_error.title }}</span>
        </v-card-title>
        <v-card-text>
          <p>{{ dialog_error.message }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click.native="closeErrorDialog">{{ $t('common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { uploadUsersCSVUrl, getHeaderMultimedia } from '@/config/config';

export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    onMaxFileSize: {
      type: Function,
    },
    onCsvFileIsUploading: {
      type: Function,
    },
    onCsvFileIsUploaded: {
      type: Function,
    },
    onError: {
      type: Function,
    },
    companyId: {
      type: Number,
    },
    userId: {
      type: Number,
    },
  },
  data: () => ({
    dialog_confirm: {
      dialog: false,
      tipo: '',
      titulo: '',
      texto: '',
      btn_confirm_texto: '',
      btn_confirm_icono: '',
      btn_confirm_color: '',
      paciente_id: 0,
      documento_id: 0,
      documento_indice: 0,
    },
    dialog_error: {
      dialog: false,
      title: '',
      message: '',
      has_error: false,
    },
    disabledButton: false,
    disabledButtonUploadCsv: false,
    nuevodocumento: {
      seleccionado: false,
      file: '',
    },
  }),

  methods: {
    ...mapActions('layout', ['showAlert']),
    selectDocument() {
      if (!this.disabledButton) {
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
          document.getElementById('filenuevo').setAttribute('accept', '*.csv');
        }
        this.$refs.filenew.click();
      }
    },
    onFileChangeNew() {
      this.nuevodocumento.seleccionado = true;
      this.nuevodocumento.file = this.$refs.filenew.files[0];
      if (typeof this.nuevodocumento.file !== 'undefined') {
        const tam_max = 20000000;
        if (this.nuevodocumento.file.size > tam_max) {
          this.onMaxFileSize(tam_max / 1000000);
        } else {
          this.dialog_confirm.dialog = true;
          this.dialog_confirm.tipo = 'subir';
          this.dialog_confirm.titulo = this.$t('documentos.confirmar_subida_titulo');
          this.dialog_confirm.texto = this.$t('documentos.confirmar_subida_texto');
          this.dialog_confirm.btn_confirm_texto = this.$t('common.confirm');
          this.dialog_confirm.btn_confirm_icono = 'mdi-cloud-upload';
          this.dialog_confirm.btn_confirm_color = 'green';
        }
      }
    },
    uploadCsvFile() {
      this.onCsvFileIsUploading();
      const formData = new FormData();
      formData.append('file', this.nuevodocumento.file);
      formData.append('company_id', this.companyId);
      formData.append('user_id', this.userId);
      this.disabledButtonUploadCsv = true;
      this.$http
        .post(uploadUsersCSVUrl, formData, { headers: getHeaderMultimedia() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.dialog_error.dialog = true;
            this.dialog_error.title = this.$t('user.csv_ok');
            this.dialog_error.message = '';
          } else if (response.data.estado === 0) {
            this.dialog_error.dialog = true;
            this.dialog_error.title = this.$t('user.csv_with_error');
            this.dialog_error.message = response.data.message;
            this.dialog_error.has_error = true;
          }
        })
        .catch(e => {
          this.showAlertMsg('error', '', e.data.error);
        })
        .finally(() => {
          this.disabledButtonUploadCsv = false;
        });

      this.onCsvFileIsUploaded();
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },

    closeErrorDialog() {
      this.dialog_error.dialog = false;
      if (this.dialog_error.has_error) {
        location.reload();
      }
    },
  },
};
</script>

<style scoped></style>
