<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
          <v-toolbar flat color="transparent" class="transparent mb-3">
            <h1 class="headline black--text">{{ $t('menu.users') }}</h1>
            <v-spacer />
            <v-text-field
              v-if="!isLoading"
              v-model="search"
              clearable
              filled
              rounded
              flat
              dense
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('user.buscar')"
              hide-details
              class="mr-2"
            />
            <template v-if="canCreateUser || !is_receptionist">
              <importCsv
                v-if="permissions.importCsvOnUsersList"
                :company-id="authUser.company.id"
                :user-id="authUser.id"
                :on-max-file-size="handleOnMaxFileSize"
                :on-csv-file-is-uploaded="handleOnCsvFileIsUploaded"
                :on-csv-file-is-uploading="handleOnCsvFileIsUploading"
                :on-error="handleOnError"
                class="mr-2"
              />
              <v-btn
                v-if="!is_receptionist || (is_receptionist && sharingReceptionist)"
                rounded
                depressed
                color="primary"
                @click="mostrarModal('nuevo', 0)"
                >{{ $t('user.nuevo') }}
              </v-btn>
            </template>
          </v-toolbar>
          <v-card>
            <div v-if="isLoading" class="pa-5 text-center">
              <v-progress-circular width="2" color="primary" indeterminate />
            </div>
            <v-data-table
              v-if="!isLoading"
              :headers="headers"
              :search="search"
              :items="usuarios"
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              item-key="name"
              :options="{
                sortBy: ['created_at'],
                sortDesc: [true],
              }"
              @page-count="pageCount = $event"
            >
              <template v-slot:item="props">
                <tr style="cursor: pointer" @click="abrirFicha(props.item.id)">
                  <td width="1%" class="pr-2">
                    <d-avatar
                      :text="props.item.nombreMarket"
                      size="24"
                      rounded
                      :two-letter="true"
                      :src="props.item.img"
                    />
                  </td>
                  <td>
                    {{ props.item.nombre === null ? props.item.name : props.item.nombre }}
                  </td>
                  <td>{{ props.item.apellidos }}</td>
                  <td>{{ props.item.email }}</td>
                  <td v-if="authUser.isSuperAdmin">{{ props.item.companyName }}</td>
                  <td>
                    <span v-tooltip="$moment(props.item.created_at).format('dddd, DD MMMM YYYY, hh:mm:ss')">{{
                      props.item.created_at | moment('DD/MM/YYYY')
                    }}</span>
                  </td>
                  <td width="1%">
                    <v-chip class="block" small color="light-grey">
                      <v-icon :color="estados[props.item.estado].color">
                        mdi-circle-medium
                      </v-icon>
                      {{ estados[props.item.estado].label }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template slot="no-data">
                {{ $t('common.no_registros') }}
              </template>

              <template slot="pageText" slot-scope="props">
                {{ $t('menu.users') }} {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }}
                {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              circle
              :total-visible="$vuetify.breakpoint.mobile ? 3 : 7"
              :length="pageCount"
            />
          </div>
        </v-card>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="alerta.color"
        top="top"
        :multi-line="mode === 'multi-line'"
        :vertical="mode === 'vertical'"
      >
        <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }} </v-icon>&nbsp;{{ alerta.texto }}
      </v-snackbar>
      <v-dialog
        v-if="authUser && authUser.rol === 1 && dialogStepToStep"
        v-model="dialogStepToStep"
        scrollable
        max-width="800px"
      >
        <steptostep
          :usuario="dataStepToStep"
          :empresa="dataStepToStep.empresa"
          :is-admin="isAdmin"
          @completadoStepToStep="completadoStep"
          @cerrar-step="cerrarStep"
        />
      </v-dialog>
      <span v-if="canCreateUser || !is_receptionist" slot="widget-content">
        <v-dialog v-model="dialog_new.dialog" width="800" :overlay="true">
          <NewProfessional
            :user-id="user_id"
            :show-module="showModule"
            :especialidades="especialidades"
            :companies="companies"
            @close-new-user="closeNewUser"
            @show-alert="showAlert"
          />
        </v-dialog>
      </span>

      <!-- Modal Eliminar usuario -->
      <span slot="widget-content">
        <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('patient.delete') }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <p>{{ $t('patient.confirm_delete') }}</p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
                ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
              >
              <v-btn color="red darken-1" text @click.native="borrarusuario(usuario.id)"
                ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </span>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getCompanies } from './users/users.api';
import ImportCsv from '@/components/common/ImportCsv.vue';
import { nameEnv, usuariosUrl, deleteUsuarioUrl, licensesEmpresaUrl, getHeader } from '../config/config';
import Steptostep from '../components/ehealth/steptostep/Main.vue';
import DAvatar from '../components/DAvatar.vue';
import NewProfessional from '../components/professionals/NewProfessional.vue';

export default {
  components: {
    NewProfessional,
    DAvatar,
    Steptostep,
    ImportCsv,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    isLoading: false,
    page: 1,
    user_id: 0,
    pageCount: null,
    itemsPerPage: 20,
    search: '',
    snackbar: false,
    timeout: 15000,
    mode: '',
    especialidades: [],
    dataStepToStep: {
      empresa: {},
    },
    dialog_delete: {
      name: '',
      dialog: false,
    },
    dialog_new: {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    },
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },
    headers: [],
    estados: [
      { color: 'red accent-1', label: 'INACTIVO' },
      { color: 'green lighten-1', label: 'ACTIVO' },
      { color: 'amber darken-1', label: 'EN PROCESO' },
    ],
    usuarios: [],
    datatable: {
      rows_per_page: vm.$t('datatable.rows_per_page'),
    },
    es_dingdoc: nameEnv === 'dingdoc',
    es_medyola: nameEnv === 'medyola',
    is_receptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    sharingReceptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['company'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['company']['sharingReceptionist'],
    canCreateUser:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3 &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== '',
    dialogStepToStep:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['formStepToStep'] !== 'undefined'
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['formStepToStep']
        : false,
    isAdmin: false,
    showModule: {
      specialties: true,
      healthCardNumber: false,
      collegiateNumber: true,
    },
    companies: [],
  }),

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      paymentSettings: state => state.app.paymentSettings,
    }),
  },

  watch: {
    page() {
      this.$scrollTo('#appRoot');
    },
  },

  created() {
    this.isAdmin = this.authUser !== null && this.authUser.rol === 1;

    const tieneAcceso =
      this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 3 || this.authUser.rol === 4);
    if (!tieneAcceso) {
      this.$router.push('/dashboard');
    }
    if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
      this.dialog_new.dialog = true;
    }
  },

  mounted() {
    this.setHeadersTable();
    this.dataListUsers();
    if (this.authUser.isSuperAdmin) {
      this.loadCompanies();
    }
  },

  methods: {
    setHeadersTable() {
      this.headers = [
        { text: '', align: 'left', value: 'avatar', sortable: false },
        { text: vm.$t('patient.first_name'), align: 'left', value: 'nombre' },
        { text: vm.$t('patient.last_name'), align: 'left', value: 'apellidos' },
        { text: vm.$t('message.email'), align: 'left', value: 'email' },
      ];
      if (this.authUser.isSuperAdmin) {
        this.headers.push({ text: vm.$t('empresa.empresa'), align: 'left', value: 'companyName' });
      }
      this.headers.push(
        { text: vm.$t('common.date_up'), align: 'left', value: 'created_at' },
        { text: vm.$t('common.status'), align: 'center', value: 'estado' }
      );
    },

    dataListUsers() {
      this.isLoading = true;
      this.$http
        .post(usuariosUrl, { id: this.authUser.id }, { headers: getHeader() })
        .then(response => {
          this.usuarios = response.data.usuarios;
          this.dataStepToStep = response.data.usuarioStepToStep;
          this.especialidades = response.data.especialidades;
          this.setShowModule(response.data.health_personnel);
        })
        .catch(err => {
          this.$log.error(err);
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async loadCompanies() {
      this.companies = await getCompanies();
    },

    setShowModule(health_personnel) {
      this.showModule.specialties = health_personnel;
      this.showModule.collegiateNumber = health_personnel;
      if (typeof this.permissions.profilesFormWithInsuredNumber !== 'undefined') {
        this.showModule.healthCardNumber = this.permissions.profilesFormWithInsuredNumber;
      }
    },

    handleOnMaxFileSize(maxSize) {
      this.alerta.icono = 'mdi-alert-circle';
      this.alerta.texto = this.$t('errors.file_max_upload', { tam: maxSize });
      this.alerta.color = 'error';
      this.snackbar = true;
      this.isLoading = false;
    },

    handleOnCsvFileIsUploading() {
      this.isLoading = true;
    },

    handleOnCsvFileIsUploaded() {
      this.alerta.texto = this.$t('documentos.csv_uploaded');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-check';
      this.snackbar = true;
      this.isLoading = false;
    },

    handleOnError() {
      this.alerta.texto = this.$t('errors.try_again');
      this.alerta.color = 'error';
      this.snackbar = true;
      this.isLoading = false;
    },
    closeNewUser() {
      if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
        this.$router.push('/dashboard');
      } else {
        this.dialog_new.dialog = false;
      }
    },

    completadoStep() {
      this.alerta.texto = this.$t('success.save_common');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-check';
      this.snackbar = true;
      this.authUser.formStepToStep = false;
      window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
      this.dialogStepToStep = false;
    },

    cerrarStep() {
      this.dialogStepToStep = false;
    },

    listarusuarios() {
      this.$http
        .post(usuariosUrl, { id: this.authUser.id }, { headers: getHeader() })
        .then(response => {
          this.usuarios = response.data;
        })
        .catch(err => {
          this.$log.error(err);
        });
    },

    mostrarModal(tipo, id) {
      if (tipo === 'delete') {
        this.dialog_delete.dialog = true;
      } else if (tipo === 'nuevo') {
        if (this.authUser.isSuperAdmin) {
          this.dialog_new.dialog = true;
          this.user_id = id;
          return;
        }

        this.$http.get(licensesEmpresaUrl, { headers: getHeader() }).then(
          () => {
            this.dialog_new.dialog = true;
            this.user_id = id;
          },
          () => {
            this.alerta.texto = this.$t('empresa.without_licenses');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
            this.snackbar = true;
          }
        );
      }
    },
    abrirFicha(id) {
      this.$router.push('/professionals/' + id);
    },

    borrarusuario(id) {
      this.dialog_delete.dialog = false;
      this.$http
        .post(deleteUsuarioUrl, { id: this.authUser.id, patient_id: id }, { headers: getHeader() })
        .then(response => {
          this.alerta.icono = 'mdi-delete';
          if (response.status === 200 && response.data === '1') {
            this.alerta.texto = this.$t('patient.delete_success');
            this.alerta.color = 'info';
            this.listarusuarios();
          } else {
            this.alerta.texto = this.$t('patient.delete_error');
            this.alerta.color = 'error';
          }
        });

      setTimeout(() => {
        this.alerta.texto = '';
      }, 5000);
    },

    showAlert(alert) {
      this.alerta = alert;
      this.snackbar = true;
    },
  },
};
</script>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

.loading_usuario {
  margin-top: 30px;
  text-align: center;
}

.titulo_modal {
  margin-top: 0px !important;
}

.davatar-vchip {
  margin: 0px 5px 0px -12px;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.v-chip.block {
  width: 100%;
}
</style>
